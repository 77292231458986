<template>
    <div>
        <van-nav-bar fixed @click-left="leftReturn" title="宽带" left-arrow>
        </van-nav-bar>
        <div class="content">
            <div class="part">
                <span class="part-subtitle">申请详情</span>
                <div class="otherExpenses-inputpart">
                    <div class="otherExpenses-inputpart-row">
                        <div class="otherExpenses-address">{{applicationAddress}}</div>
                    </div>
                    <div class="otherExpenses-inputpart-column">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <div class="otherExpenses-inputpart-row-left">
                                <span class="part-inputpart-row-header">申请人：</span>
                                <span>{{applicant}}</span>
                            </div>
                            <div class="otherExpenses-inputpart-row-right">
                                <span class="part-inputpart-row-header">联系方式：</span>
                                <span>{{contactInformation}}</span>
                            </div>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">订单来源：</span>
                            <span>{{orderSource}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预约时间：</span>
                            <span class="part-inputpart-row-redtext">{{appointmentTime}}</span>
                        </div>
                        <div>
                            <span class="part-inputpart-row-header">申请原因 ：</span>
                            <span class="part-inputpart-row-wrap">{{reasonForApplication}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="part">
                <div class="otherExpenses-inputpart">
                    <div class="otherExpenses-inputpart-column" style="margin-top: 0">
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">宽带提供商：</span>
                            <span>{{broadbandProvider}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预计安装时间：</span>
                            <span class="part-inputpart-row-redtext">{{estimatedInstallationTime}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">宽带型号：</span>
                            <span>{{broadbandModel}}</span>
                        </div>
                        <div class="otherExpenses-inputpart-row-bottom">
                            <span class="part-inputpart-row-header">预计费用：</span>
                            <span>{{estimatedCost}}元</span>
                        </div>
                    </div>
                </div>
                <div id="undertakerPanel" class="part-inputpart-row" @click="isUndertakerShow = !isUndertakerShow">
                    <span :class=" isUndertakerSelected ? 'content-have' : 'content-none' "></span>
                    <span class="part-inputpart-row-header">承担方</span>
                    <span class="content-divide">|</span>
                    <span class="width">{{undertaker}}</span>
                    <img class="part-inputpart-row-right" :class=" isUndertakerShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
                </div>
<!--                <div>-->
<!--                    <div class="part-inputpart part-inputpart-dropDownList" v-if="isUndertakerShow">-->
<!--                        <div class="part-inputpart-dropDownList-option" :class="item==undertaker ? 'part-inputpart-dropDownList-option-selected' : '' " v-for="item in undertakerArr" @click="selectUndertakerOption(item)">{{item}}</div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="part-inputpart-row">
                    <span :class=" 0==lastFee.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">完成费用</span>
                    <span class="content-divide">|</span>
                    <span>￥</span>
                    <input type="tel" v-model="lastFee">
                    <span class="part-inputpart-row-right" style="color: #d8d8d8">元</span>
                </div>
                <div id="finishTimePanel" class="part-inputpart-row" @click="clickFinishTimeShow">
                    <span :class=" 0==finishTime.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">完成时间</span>
                    <span class="content-divide">|</span>
                    <span class="width">{{finishTime}}</span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
                </div>
                <div>
                    <div class="part-inputpart part-inputpart-dropDownList" v-if="isFinishTimeShow">
                        <van-datetime-picker
                                v-model="currentDate"
                                type="date"
                                title="选择年月日"
                                item-height="35px"
                                @confirm="finishTimeConfirm"
                                @cancel="finishTimeCancel"
                        />
                    </div>
                </div>
                <div id="expirationTimePanel" class="part-inputpart-row" @click="clickExpirationTimeShow">
                    <span :class=" 0==expirationTime.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">到期时间</span>
                    <span class="content-divide">|</span>
                    <span class="width">{{expirationTime}}</span>
                    <img class="part-inputpart-row-right part-inputpart-row-right-timepick" src="../../../assets/images/timepick.png">
                </div>
                <div>
                    <div class="part-inputpart part-inputpart-dropDownList" v-if="isExpirationTimeShow">
                        <van-datetime-picker
                                v-model="currentDate"
                                type="date"
                                title="选择年月日"
                                item-height="35px"
                                @confirm="expirationTimeConfirm"
                                @cancel="expirationTimeCancel"
                        />
                    </div>
                </div>
                <div class="part-inputpart-row">
                    <span :class=" 0==broadbandAccount.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">宽带账号</span>
                    <span class="content-divide">|</span>
                    <input v-model="broadbandAccount" placeholder="请输入账号">
                </div>
                <div class="part-inputpart-row">
                    <span :class=" 0==broadbandPassword.trim().length ? 'content-none' : 'content-have' "></span>
                    <span class="part-inputpart-row-header">宽带密码</span>
                    <span class="content-divide">|</span>
                    <input type="password" v-model="broadbandPassword" placeholder="请输入密码">
                </div>
                <div class="part-inputpart">
                    <div class="part-inputpart-textarea">
                        <div class="part-inputpart-row">
                            <span class="otherExpenses-row-enablenon part-inputpart-row-header">处理反馈</span>
                            <span class="smallgraytext">(非必填)</span>
                        </div>
                        <textarea v-model="afterRentDesprition"></textarea>
                    </div>
                    <div class="part-uploadpic-content">
                        <div class="part-uploadpic-module" v-for="(item,index) in imgList" :key="index" >
                            <img class="part-uploadpic-module-pic" :src="item.content">
                        </div>
                        <!--                        上传图片按钮-->
                        <van-uploader
                                :preview-image="false"
                                v-model="imgList"
                                multiple
                                :max-count="10"
                        >
                            <div class="part-inputpart-cardpic-button">
                                <span class="part-inputpart-cardpic-text">上传图片</span>
                            </div>
                        </van-uploader>
                    </div>
                </div>
                <div @click="submit" class="part-button" :class=" !isUndertakerSelected || 0==lastFee.trim().length || 0==finishTime.trim().length || 0==expirationTime.trim().length || 0==broadbandAccount.trim().length || 0==broadbandPassword.trim().length ? '' : 'part-button-enabled' ">提交</div>
            </div>
        </div>
      <van-popup v-model="isUndertakerShow" position="bottom">
        <van-picker
            show-toolbar
            :columns="undertakerArr"
            @cancel="isUndertakerShow = false"
            :default-index="undertakerIndex"
            @confirm="selectUndertakerOption"
        />
      </van-popup>
    </div>
</template>

<script>
import {
  NavBar,
  Image as VanImage,
  ImagePreview,
  DatetimePicker,
  Uploader, Picker, Popup
} from 'vant'

    export default {
        name: "BroadbandCompletion",

        components: {
            [NavBar.name]: NavBar,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [VanImage.name]:VanImage,
            [ImagePreview.Component.name]: ImagePreview.Component,
          [Picker.name]: Picker,
          [Popup.name]: Popup,
        },

        data() {
            return {
              undertakerIndex: '',
                isUndertakerShow: false,
                isUndertakerSelected: false,
                isFinishTimeShow: false,
                isExpirationTimeShow: false,
                currentDate: new Date(),
                // 申请地址
                applicationAddress: '中海紫金冠地-星海公园-中山路535-1-3号1单元501',
                // 申请人
                applicant: '沉思',
                // 联系方式
                contactInformation: '13412345678',
                // 订单来源
                orderSource: '公司',
                // 预约时间
                appointmentTime: '2020-3-10 12:2:33',
                // 申请原因
                reasonForApplication: '这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了这里坏了',
                // 宽带供应商
                broadbandProvider: '短款的',
                // 预计安装时间
                estimatedInstallationTime: '2029-12-58 12:2:33',
                //宽带型号
                broadbandModel: 'AS738649237',
                // 预计费用
                estimatedCost: '38382',
                //承担方
                undertaker: '',
                // 完成费用
                lastFee: '',
                // 完成时间
                finishTime: '',
                //到期时间
                expirationTime: '',
                //宽带账号
                broadbandAccount: '',
                //宽带密码
                broadbandPassword: '',
                // 处理反馈
                afterRentDesprition: '',
                //承租方下拉列表
                undertakerArr: [
                    '测试1',
                    '测试2',
                    '测试3'
                ],
                // 反馈图片列表
                imgList: []
            }
        },

        methods: {
            //返回上一层
            leftReturn() {
                this.$router.go(-1);
            },

            submit() {
                if(!isUndertakerSelected || 0==lastFee.trim().length || 0==finishTime.trim().length || 0==expirationTime.trim().length || 0==broadbandAccount.trim().length || 0==broadbandPassword.trim().length) return
                //提交之后的操作
            },

            //点击下拉框区域外 下拉框隐藏
            clickUndertakerShow() {
                this.isUndertakerShow = !this.isUndertakerShow;
                var panel = document.getElementById('undertakerPanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isUndertakerShow = false
                        }
                    })
                }

            },
            //点击下拉框选项 进行数据绑定
            selectUndertakerOption(item,index){
                this.undertaker = item
              this.undertakerIndex = index
                this.isUndertakerShow = false
                this.isUndertakerSelected = true

            },

            clickFinishTimeShow() {
                this.isFinishTimeShow = !this.isFinishTimeShow;
                var panel = document.getElementById('finishTimePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isFinishTimeShow = false
                        }
                    })
                }
            },
            //日期选择组件 点击确认键后执行方法
            finishTimeConfirm(value){
                var date = new Date(value)
                this.finishTime = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
                this.isFinishTimeShow = false
            },
            //日期选择组件 点击取消键后执行方法
            finishTimeCancel(){
                this.isFinishTimeShow = false
            },

            //到期时间
            clickExpirationTimeShow() {
                this.isExpirationTimeShow = !this.isExpirationTimeShow;
                var panel = document.getElementById('expirationTimePanel')
                if(panel){
                    document.addEventListener('click',e =>{
                        if(!panel.contains(e.target)){
                            this.isExpirationTimeShow = false
                        }
                    })
                }
            },
            expirationTimeConfirm(value){
                var date = new Date(value)
                this.expirationTime = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
                this.isExpirationTimeShow = false
            },
            expirationTimeCancel(){
                this.isExpirationTimeShow = false
            },

            previewImg(index){
                ImagePreview(
                    {
                        images: this.acceptComplaintsInfo.picList,
                        startPosition: index,
                        onClose() {
                            // do something
                        },
                    }
                )
            },
        }

    }
</script>

<style scoped>

    .content {
        margin-top: 45px;
        font-size: 14px;
    }

    .part {
        margin: 15px;
    }

    .part-subtitle {
        color: #ff5d3b;
        font-size: 12px;
        font-weight: 900;
        position: relative;
        top: 8px;
    }

    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
    }

    .part-inputpart-row {
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        align-items: center;
        height: 45px;
        background-color: #ffffff;
        margin-top: 15px;
    }

    .part-inputpart-row-header {
        font-weight: 900;
        font-size: 15px;
        white-space: nowrap;
    }

    .otherExpenses-inputpart {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 10px;
    }

    .otherExpenses-inputpart-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row input {
        border: 0;
        width: 100%;
    }

    .part-inputpart-row input::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .otherExpenses-address{
        padding: 15px 10px 15px 15px;
        font-size: 16px;
        font-weight: 900;
    }

    .otherExpenses-inputpart-row-bottom {
        margin-bottom: 5px;
        display: flex;
        align-items: baseline;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .otherExpenses-inputpart-row-left {
        display: inline-block;
        width: 47%;
    }

    .otherExpenses-inputpart-row-right {
        display: inline-block;
        width: 53%;
    }

    .otherExpenses-inputpart-column {
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 16px 15px;
        justify-content: space-between;
        background-color: #ffffff;
        margin-top: 2px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 15px;
    }

    .part-inputpart-row-wrap {
        line-height: 24px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px 0 15px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }

    .otherExpenses-row-enablenon{
        margin-left: 15px;
    }

    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
    }

    .part-inputpart-row-normaltext {
        color: black;
        font-weight: 900;
    }

    .part-inputpart-row-redtext {
        width: 100%;
        color: #ff5d3b;
    }

    .part-inputpart-textarea {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        background-color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .part-inputpart-textarea div:first-child {
        margin-top: 0;
    }

    .part-inputpart-textarea textarea {
        height: 60px;
        background-color: #FaFaFa;
        border-radius: 8px;
        padding: 10px;
        margin: 0px 15px;
        border: none;
        resize: none;
    }

    .part-inputpart-textarea textarea::-webkit-input-placeholder {
        color: #d8d8d8;
    }

    .part-button {
        height: 45px;
        border-radius: 10px;
        background-color: #ededed;
        color: #ffffff;
        text-align: center;
        line-height: 45px;
        font-size: 15px;
        font-weight: 900;
        margin-top: 40px;
        margin-bottom: 50px;
    }

    .part-button-enabled {
        background-image: linear-gradient(to right, #ffc274, #ff5d3b);
    }

    .smallgraytext{
        color: #D8D8D8;
        font-size: 12px;
        margin-bottom: -3px;
    }

    .part-inputpart-row-right-timepick{
        width: 20px;
    }

    .part-inputpart-dropDownList{
        height: 23%;
        /*垂直方向滚动*/
        /*overflow-y: scroll;*/
        overflow: auto;
        width: 92.5%;
        position: absolute;
        z-index: 99;
    }

    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected{
        color: #ff5d3b;
    }

    .part-uploadpic-content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .part-uploadpic-module{
        margin: 10px 20px 10px 15px;
    }

    .part-uploadpic-module-pic{
        width: 80px;
        height: 80px;
        border-radius: 8px;
    }

    .part-inputpart-cardpic-text{
        position: absolute;
        top: 62px;
        left: 31px;
        font-size: 12px;
        color: #d8d8d8;
    }

    .part-inputpart-cardpic-button{
        display: inline-block;
        background: url("../../../assets/images/grayAdd.png") center center no-repeat;
        width: 80px;
        height: 80px;
        border-radius: 8px;
        background-size: 30px;
        background-color: #fafafa;
        margin: 10px 15px;
    }

    .width{
        width: 100%;
    }

    .part-inputpart-row-right-downArrow{
        width: 9px;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }


</style>

